import React, { useState, useEffect } from "react";
import {
  MessageCircle,
  Send,
  User,
  X,
  Home,
  HelpCircle,
  Search,
  MessageSquare,
  Building2,
  Users,
  Megaphone,
  LayoutGrid,
  ChevronRight,
  ExternalLink,
  ArrowLeft,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import "./Chatbot.css";

// Modified HelpSection component with proper scrolling
const HelpSection = ({ selectedHelp, setSelectedHelp }) => {
  if (selectedHelp) {
    return (
      <motion.div
        className="flex flex-col h-full bg-white overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className="p-4 border-b flex-shrink-0"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.button
            onClick={() => setSelectedHelp(null)}
            className="flex items-center text-gray-600 hover:text-[#09AFF4]"
            whileHover={{ x: -5 }}
            whileTap={{ scale: 0.95 }}
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </motion.button>
        </motion.div>
        <motion.div
          className="flex-1 overflow-y-auto"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <div className="p-6">
            <div className="max-w-3xl mx-auto">
              <motion.h2
                className="text-xl font-semibold mb-4"
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {selectedHelp.title}
              </motion.h2>
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <motion.div
                  className="flex-1"
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                    {selectedHelp.content}
                  </p>
                </motion.div>
                <motion.div
                  className="w-full md:w-1/3 rounded-lg overflow-hidden shadow-md"
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.6 }}
                >
                  <img
                    src={selectedHelp.image}
                    alt={selectedHelp.title}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="flex flex-col h-full bg-gray-50 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex-1 overflow-y-auto p-4">
        <div className="grid gap-3">
          {HELP_SECTIONS.map((section, index) => {
            const Icon = section.icon;
            return (
              <motion.button
                key={index}
                onClick={() => setSelectedHelp(section)}
                className="w-full bg-white p-4 rounded shadow-sm border border-gray-100 hover:border-[#09AFF4] transition-colors text-left flex items-center justify-between group"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-center space-x-3">
                  <motion.div
                    className="w-10 h-10 rounded-lg bg-blue-50 flex items-center justify-center"
                    whileHover={{ rotate: 10 }}
                  >
                    <Icon className="w-5 h-5 text-[#09AFF4]" />
                  </motion.div>
                  <span className="text-gray-700 font-medium group-hover:text-[#09AFF4]">
                    {section.title}
                  </span>
                </div>
                <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-[#09AFF4]" />
              </motion.button>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

const FAQ_QUESTIONS = [
  {
    question: "What is EIM?",
    answer:
      "Elevate by Incubation Masters (EIM) is a 3D immersive business incubation program designed to empower startups in Asian countries. By integrating AI-driven financial modeling, real-time analytics, and a comprehensive virtual ecosystem, EIM provides startups with essential tools and mentorship to navigate challenges and accelerate growth. Through a single virtual platform, startups can collaborate, network, access funding opportunities, and receive expert guidance from mentors, investors, and industry professionals.",
    image: "../../assets/images/5.png",
  },
  {
    question: "Who is EIM for?",
    answer:
      "EIM is for early-stage and scaling startups in Asian countries looking for mentorship, networking, funding opportunities, and business resources to accelerate their growth.",
    image: "../../assets/images/6.png",
  },
  {
    question: "How does EIM help me in my startup journey?",
    answer:
      "EIM provides a structured ecosystem with AI-driven business modeling, mentor matchmaking, investor access, real-time analytics, and vital startup resources to help entrepreneurs scale efficiently.",
    image: "../../assets/images/7.png",
  },
  {
    question: "What’s the pricing model for EIM?",
    answer:
      "EIM offers flexible pricing plans tailored to different startup needs. Contact our team for detailed pricing and subscription options.",
    image: "../../assets/images/8.png",
  },
  {
    question: "Which EIM plan is right for me?",
    answer:
      "The right plan depends on your startup's stage, funding needs, and mentorship requirements. Our team can guide you in selecting the best plan based on your business goals.",
    image: "../../assets/images/5.png",
  },
  {
    question: "How do I get started with EIM?",
    answer:
      "Getting started is easy! Simply create an account, complete your profile, and you're ready to go. We offer a guided tutorial for new users.",
    image: "../../assets/images/6.png",
  },
  {
    question: "EIM Blogs ",
    answer:
      "Stay updated with insights, trends, and success stories from the startup world by exploring our blog section.",
    image: "../../assets/images/7.png",
  },
  {
    question: "How can I contact support?",
    answer:
      "You can reach our support team via email, live chat on our platform, or by submitting a request through our contact form.",
    image: "../../assets/images/8.png",
  },
];

const HELP_SECTIONS = [
  {
    title: "AI-Powered Startup Growth Platform",
    icon: Building2,
    content:
      "EIM is more than just an incubation program—it’s an AI-powered startup growth platform designed to help entrepreneurs validate ideas, analyze markets, automate business planning, gain customer insights, and secure funding opportunities. By leveraging AI and data-driven strategies, EIM provides a virtual ecosystem where startups can access mentorship, collaborate with industry experts, and build sustainable business models. Whether you're in the ideation phase or scaling your business, our platform equips you with the right tools, knowledge, and network to thrive.",
    image: "../../assets/images/1.png",
  },
  {
    title: "Idea Validation & Refinement",
    icon: Users,
    content:
      "Turning an idea into a successful business requires more than just passion—it demands data-driven validation and strategic refinement.With EIM, startups can:✅ Test their business idea using AI-powered validation tools ✅ Identify potential risks and challenges early ✅ Get expert feedback from mentors and industry specialists ✅ Refine their value proposition based on real market insights Our structured framework helps entrepreneurs refine their ideas into scalable, high - impact businesses before making significant investments.",
    image: "../../assets/images/2.png",
  },
  {
    title: "AI-Driven Market Research",
    icon: Megaphone,
    content:
      "Understanding your market is crucial for startup success. EIM’s AI-driven market research tools empower startups with:🔹 Real-time industry insights to identify trends and opportunities 🔹 Competitor analysis to understand strengths, weaknesses, and market gaps 🔹 Target audience profiling to refine customer segments🔹 Automated data collection for a faster and more accurate research process With AI-enhanced analytics, startups can make informed decisions, reduce risk, and gain a competitive edge in their industry.",
    image: "../../assets/images/3.png",
  },
  {
    title: "Automated Business Planning",
    icon: LayoutGrid,
    content:
      "Creating a solid business plan is essential for securing investment and scaling effectively. EIM’s AI-powered business planning streamlines this process by: ✅ Generating customized business models tailored to your industry ✅ Providing automated financial projections and revenue modeling ✅ Offering ready - to - use templates for pitch decks, strategy outlines, and investor reports ✅ Guiding startups through business strategy formulation With AI automation, startups can build a structured, data - driven roadmap to attract investors and ensure long - term success.",
    image: "../../assets/images/4.png",
  },
  {
    title: "Intelligent Customer Insights",
    icon: Building2,
    content:
      "Understanding your customers goes beyond basic demographics—it’s about knowing their behaviors, preferences, and needs. EIM’s AI-driven system provides: 🔹 Deep customer segmentation based on behavior, purchase patterns, and engagement levels 🔹 Predictive analytics to forecast customer demand and optimize marketing strategies 🔹 Automated feedback analysis for real-time product/service improvement 🔹 AI-driven persona creation to enhance personalization and user experience With actionable insights, startups can craft customer-centric strategies that improve retention, increase conversions, and drive growth.",
    image: "../../assets/images/1.png",
  },
  {
    title: "Personalised Mentorship & Guidance",
    icon: Users,
    content:
      "One of EIM’s most valuable offerings is its tailored mentorship program. Unlike generic advice, we match startups with mentors based on: ✅ Industry expertise and sector-specific knowledge ✅ Business challenges and growth goals ✅ Funding stage and scalability potential ✅ Technology and product alignment Through one-on-one mentorship sessions, group discussions, and structured coaching, startups receive actionable guidance to navigate challenges, refine strategies, and accelerate success.",
    image: "../../assets/images/2.png",
  },
  {
    title: "Getting Started, Account Management, & Support",
    icon: Megaphone,
    content:
      "Starting your journey with EIM is quick and simple. Follow these steps: 1️⃣ Sign Up – Create an account and set up your startup profile2️⃣ Explore the Platform – Access AI tools, mentorship, and investor networks 3️⃣ Engage with Experts – Get matched with the right mentors and resources4️⃣ Monitor & Scale – Use real-time analytics to track growth and optimize strategies For account management, plan upgrades, or troubleshooting, our support team is available 24/7 via: 📧 Email Support 💬 Live Chat 📞 Customer Helpline We ensure a seamless experience so you can focus on building and growing your business.",
    image: "../../assets/images/3.png",
  },
  {
    title: "Plan Comparison & Pricing",
    icon: LayoutGrid,
    content:
      "EIM offers flexible pricing plans based on your startup’s stage, funding needs, and mentorship requirements. 🔹 Basic Plan – Ideal for early - stage startups needing AI - powered market research and business planning tools. 🔹 Growth Plan – Includes tailored mentorship, investor access, and advanced analytics for scaling businesses. 🔹 Premium Plan – Full - suite access with dedicated funding guidance, compliance support, and exclusive networking opportunities. Our pricing model ensures that every startup—big or small—gets the support they need at an affordable cost. Need help choosing the right plan ? Contact our team for a personalized consultation.",
    image: "../../assets/images/4.png",
  },
];

const SYSTEM_PROMPT = `You are an AI assistant for Incubation Masters, named Eimee. And here are some information about Incubation Masters. As an AI assistant you should know about Incubation Masters. 

Incubation Masters is a global startup and incubation consulting firm, recognized as Southeast Asia’s largest startup and incubation network. We specialize in fostering entrepreneurship, innovation, and economic growth, working with startups, governments, universities, and investors to build world-class incubation ecosystems.
Who We Work With
We collaborate with a diverse set of clients, including:
Top-tier universities – Helping institutions establish world-class incubation and entrepreneurship programs.
Smart cities & government bodies – Supporting innovation-led policies for sustainable economic growth.
Government of Madhya Pradesh & Uttar Pradesh – Partnering in flagship initiatives for startup and trade development.
Startup Idea & early-stage ventures – Enabling founders to scale their ideas into successful businesses.
Trade & Export Promotion
We also operate a dedicated trade and export promotion vertical in partnership with the Government of Madhya Pradesh, facilitating global market access, trade expansion, and business competitiveness.
Our Expertise & Impact
With vast experience in organizing large-scale startup and investment events, we have successfully executed:
•	Global Investors Summits for the Government of Madhya Pradesh
•	Regional Industry Conclaves
•	Startup Hackathons & Bootcamps
•	Shark Tanks & Investment Programs
•	Angel Investment Masterclasses to create local angel investor networks
We are committed to nurturing startup ecosystems in smaller towns, with success stories from Jabalpur, Satna, Sagar, Jhansi, Gwalior, Ujjain, Bhopal, as well as Canada and Mexico.
Our Legacy & Global Presence
Founded in 2019 by CK Tiwari and Ravi K Tiwari, Incubation Masters is headquartered in India, with branch offices in Canada and Mexico. Today, we are the most preferred choice for startups in Southeast Asia, offering end-to-end support across all stages and sectors.
EIM – Elevate by Incubation Masters
EIM (Elevate by Incubation Masters) is a SaaS-based product designed to help startups worldwide accelerate their growth.
EIM offers:
✔ AI-driven insights & business recommendations
✔ A resourceful global startup network
✔ Direct access to investors, interns, tech talent & mentors
✔ A virtual ecosystem for seamless collaboration
Startups can subscribe to EIM through four tailored plans:
Alpha (30-day free trial for ideation-stage startups)
Beta, Gamma & Sigma (More details available here
Email us at: info@incubationmasters.com
Visit our website: www.incubationmasters.com
At Incubation Masters, we are shaping the future of startups, trade, and innovation. Join us in creating the next big success story! 

Important Response Guidelines For Eimee
1. Keep responses concise and professional (2-3 sentences max)
2. Focus on practical, actionable advice
3. Relate answers to IM's services when relevant
4. Be direct and solution-oriented

If a question is outside IM's scope, politely redirect to relevant services or suggest scheduling a consultation or write us an email to info@incubationmasters.com`;

function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("home");
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [selectedHelp, setSelectedHelp] = useState(null);
  const [input, setInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredQuestions, setFilteredQuestions] = useState(FAQ_QUESTIONS);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [messages, setMessages] = useState([]);
  const welcomeMessages = [
    {
      sender: "bot",
      text: "Hi there! Welcome to EIM Support.",
    },
    {
      sender: "bot",
      text: "I am Eimee, your AI assistant and I am ready to help you with your questions about EIM, the most beloved startup product in the world.",
    },
    {
      sender: "bot",
      text: "How can I help you today?",
    },
  ];
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = React.useRef(null);

  const generateChatGPTPrompt = (userMessage) => ({
    model: "gpt-3.5-turbo", // or "gpt-3.5-turbo"
    messages: [
      { role: "system", content: SYSTEM_PROMPT }, // System prompt remains the same
      { role: "user", content: userMessage },
    ],
  });

  const renderMessages = () => (
    <div className="flex flex-col h-full bg-gray-50">
      <div className="flex-1 overflow-y-auto p-3 space-y-3">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${
              msg.sender === "user" ? "justify-end" : "justify-start"
            } animate-fadeIn`}
          >
            <div
              className={`flex items-end space-x-2 max-w-[70%] ${
                msg.sender === "user"
                  ? "flex-row-reverse space-x-reverse"
                  : "flex-row"
              }`}
            >
              {msg.sender === "bot" && (
                <div className="flex flex-col items-center space-y-1">
                  <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0 shadow-sm">
                    <img
                      src="../../assets/chatbotgifs/avatar.png"
                      alt="Bot Avatar"
                      className="w-full h-full img-fluid object-fit-cover object-position-top"
                    />
                  </div>
                  <span className="text-xs text-gray-500">Eimee</span>
                </div>
              )}

              <div
                className={`${
                  msg.sender === "user"
                    ? "bg-gradient-to-r from-[#09AFF4] to-blue-500 text-white rounded-t-xl rounded-bl-xl"
                    : "bg-white text-gray-800 rounded-t-xl rounded-br-xl shadow-sm"
                } py-2 px-3 text-sm leading-normal relative`}
              >
                <p className="text-[14px]">{msg.text}</p>
              </div>

              {msg.sender === "user" && (
                <div className="w-6 h-6 rounded-circle bg-gradient-to-r from-[#09AFF4] to-blue-500 flex items-center justify-center flex-shrink-0 shadow-sm">
                  <User className="w-3 h-3 text-white" />
                </div>
              )}
            </div>
          </div>
        ))}

        {isTyping && (
          <div className="flex justify-start">
            <div className="flex items-end space-x-2">
              <div className="flex flex-col items-center space-y-1">
                <div className="w-8 h-8 rounded-full overflow-hidden flex-shrink-0 shadow-sm">
                  <img
                    src="../../assets/chatbotgifs/avatar.png"
                    alt="Bot Avatar"
                    className="w-full h-full img-fluid object-fit-cover object-position-top"
                  />
                </div>
                <span className="text-xs text-gray-500">Eimee</span>
              </div>
              <div className="bg-white rounded-t-xl rounded-br-xl py-2 px-3 shadow-sm">
                <div className="flex space-x-1">
                  <div
                    className="w-1.5 h-1.5 bg-[#09AFF4] rounded-circle animate-bounce"
                    style={{ animationDelay: "0ms" }}
                  />
                  <div
                    className="w-1.5 h-1.5 bg-[#09AFF4] rounded-circle animate-bounce"
                    style={{ animationDelay: "200ms" }}
                  />
                  <div
                    className="w-1.5 h-1.5 bg-[#09AFF4] rounded-circle animate-bounce"
                    style={{ animationDelay: "400ms" }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-3 border-t border-gray-200 bg-white">
        <form onSubmit={sendMessage} className="flex gap-2">
          <input
            type="text"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-1 px-3 py-2 text-sm text-gray-800 placeholder-gray-400 bg-gray-50 rounded-lg border border-gray-200 focus:outline-none focus:border-[#09AFF4] focus:ring-1 focus:ring-[#09AFF4] transition-colors"
          />
          <button
            type="submit"
            className="px-3 py-2 bg-gradient-to-r from-[#09AFF4] to-blue-500 hover:opacity-90 text-white rounded transition-all shadow-sm flex items-center justify-center"
          >
            <Send className="w-4 h-4" />
          </button>
        </form>
      </div>
    </div>
  );

  const sendMessage = async (e) => {
    e?.preventDefault();
    if (!input.trim()) return;

    // Add user message
    setMessages((prev) => [...prev, { sender: "user", text: input }]);
    const userMessage = input;
    setInput("");
    setIsTyping(true);

    try {
      const prompt = generateChatGPTPrompt(userMessage);
      console.log("Sending request to OpenAI API...", prompt); // Debug log
      const response = await axios.post(
        process.env.REACT_APP_OPENAI_API_URL,
        prompt,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OpenAI API Response:", response.data); // Debug log

      const botResponse =
        response.data.choices?.[0]?.message?.content ||
        "I couldn't generate a response. Please try again.";

      setMessages((prev) => [...prev, { sender: "bot", text: botResponse }]);
    } catch (error) {
      console.error("Error calling ChatGPT API:", error);
      setMessages((prev) => [
        ...prev,
        {
          sender: "bot",
          text: "I apologize, but I'm having trouble processing your request at the moment. Please try again later.",
        },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  // Function to add messages with delay
  const addMessagesSequentially = async () => {
    for (let i = 0; i < welcomeMessages.length; i++) {
      setIsTyping(true);
      // Wait for 1 second to simulate typing
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setMessages((prev) => [...prev, welcomeMessages[i]]);
      setIsTyping(false);
      // Wait for 500ms between messages
      if (i < welcomeMessages.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  };
  useEffect(() => {
    addMessagesSequentially();
  }, []);

  const renderHome = () => (
    <div className="flex flex-col h-full overflow-hidden">
      {/* Header section with gradient background */}
      <div className="gradient-bg p-6 pb-1 flex-shrink-0 relative">
        {/* Avatar Container - Moved slightly to maintain text visibility */}
        <div className="absolute right-6 top-8 w-20 h-20 rounded-full overflow-hidden border-2 border-white/30 shadow-lg">
          <img
            src="../../assets/chatbotgifs/avatar2.gif"
            alt="Eimee Avatar"
            className="w-full h-full object-cover object-center"
          />
        </div>

        {/* Text content with better spacing and alignment */}
        <div className="max-w-[75%]">
          {" "}
          {/* Constrain width to prevent overlap */}
          <h1 className="text-white opacity-50 text-2xl font-semibold mb-3">
            Elevate by IM
          </h1>
          <h2 className="text-white/90 text-md">
            Hello! I am your AI assistant Eimee👋
          </h2>
        </div>
      </div>

      {/* Content section with improved spacing and visibility */}
      <div className="flex-1 overflow-y-auto px-4 mt-1 pb-4">
        {/* FAQ Questions with improved visibility */}
        <div className="space-y-3 mb-4">
          {filteredQuestions.map((faq, index) => (
            <motion.button
              key={index}
              onClick={() => setSelectedFAQ(faq)}
              className="w-full bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:border-[#09AFF4] transition-colors text-left flex justify-between items-center group"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <span className="text-gray-700 group-hover:text-[#09AFF4] pr-4">
                {faq.question}
              </span>
              <ChevronRight className="w-5 h-5 text-gray-400 group-hover:text-[#09AFF4] flex-shrink-0" />
            </motion.button>
          ))}
        </div>

        {/* Blog Link */}
        <motion.button
          onClick={() => window.open("https://syndie.blog", "_blank")}
          className="w-full bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:border-[#09AFF4] transition-colors text-left flex justify-between items-center mb-4 group"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <span className="text-gray-700 group-hover:text-[#09AFF4]">
            IM Blog
          </span>
          <ExternalLink className="w-5 h-5 text-gray-400 group-hover:text-[#09AFF4]" />
        </motion.button>

        {/* Send Message Button */}
        <motion.button
          onClick={() => setActiveSection("messages")}
          className="w-full bg-white p-4 rounded-lg shadow-sm border border-gray-100 hover:border-[#09AFF4] transition-colors text-left space-y-1"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <div className="text-gray-700 font-medium">Send us a message</div>
          <div className="text-gray-500 text-sm">
            We will reply as soon as we can
          </div>
        </motion.button>
      </div>
    </div>
  );

  const renderFAQDetail = () => (
    <motion.div
      className="flex flex-col h-full bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="p-4 border-b"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <motion.button
          onClick={() => setSelectedFAQ(null)}
          className="flex items-center text-gray-600 hover:text-[#09AFF4]"
          whileHover={{ x: -5 }}
          whileTap={{ scale: 0.95 }}
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back
        </motion.button>
      </motion.div>
      <motion.div
        className="flex-1 p-6 overflow-y-auto"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <div className="max-w-3xl mx-auto">
          <motion.h2
            className="text-xl font-semibold mb-4"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {selectedFAQ.question}
          </motion.h2>
          <div className="flex flex-col md:flex-row gap-6 items-start">
            <motion.div
              className="flex-1"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <p className="text-gray-600 leading-relaxed">
                {selectedFAQ.answer}
              </p>
            </motion.div>
            <motion.div
              className="w-full md:w-1/3 rounded-lg overflow-hidden shadow-md"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <img
                src={selectedFAQ.image}
                alt={selectedFAQ.question}
                className="w-full h-auto object-cover"
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const filtered = FAQ_QUESTIONS.filter(
      (faq) =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchQuery]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add new responsive styles and classes
  const getChatbotDimensions = () => {
    if (window.innerWidth <= 640) {
      // Mobile
      return {
        width: "100vw",
        height: "100vh",
        maxWidth: "100%",
        maxHeight: "100%",
        position: "fixed",
        top: "10px",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: "9999",
      };
    } else if (window.innerWidth <= 1024) {
      // Tablet
      return selectedFAQ || selectedHelp
        ? { width: "600px", height: "500px" }
        : { width: "350px", height: "500px" };
    } else {
      // Desktop
      return selectedFAQ || selectedHelp
        ? { width: "800px", height: "600px" }
        : { width: "400px", height: "600px" };
    }
  };

  const getChatContentStyles = () => {
    if (window.innerWidth <= 640) {
      return {
        flex: "1",
        overflowY: "auto",
        paddingBottom: "env(safe-area-inset-bottom)",
        height: "calc(100vh - 60px - 64px)", // Account for header and bottom nav
        paddingTop: "10px", // Remove any top padding that might cause issues
      };
    }
    return {};
  };

  const getMobileContainerStyles = () => {
    if (window.innerWidth <= 640) {
      return {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "9999",
        display: "flex",
        flexDirection: "column",
        height: "100vh", // Use viewport height
        backgroundColor: "white", // Ensure background is solid
      };
    }
    return {};
  };

  const chatbotVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 20,
      borderRadius: "16px", // Add border radius to initial state
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      borderRadius: "16px", // Maintain border radius in visible state
      transition: {
        type: "spring",
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 20,
      borderRadius: "16px", // Maintain border radius in exit state
      transition: {
        duration: 0.3,
      },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10,
      },
    },
    tap: { scale: 0.95 },
  };

  return (
    <div
      className={`fixed ${
        window.innerWidth <= 640 ? "inset-0" : "bottom-5 right-5"
      } z-50`}
    >
      <AnimatePresence>
        {!isOpen ? (
          <motion.button
            onClick={() => setIsOpen(true)}
            className="fixed bottom-4 right-4 w-12 h-12 bg-[#09AFF4] rounded-circle flex items-center justify-center text-white shadow-lg hover:bg-[#09AFF4]/90 transition-colors"
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            <MessageCircle className="w-5 h-5" />
          </motion.button>
        ) : (
          <motion.div
            className="bg-white flex flex-col overflow-hidden shadow-xl"
            style={{
              ...getChatbotDimensions(),
              ...getMobileContainerStyles(),
            }}
            variants={chatbotVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            layout // Add layout prop to handle smooth transitions
          >
            {/* Header with responsive border radius */}
            <motion.div
              className="gradient-bg p-4 relative z-10"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              style={{
                position: window.innerWidth <= 640 ? "sticky" : "relative",
                top: window.innerWidth <= 640 ? "0" : "auto",
                borderTopLeftRadius: window.innerWidth <= 640 ? "16px" : "16px",
                borderTopRightRadius:
                  window.innerWidth <= 640 ? "16px" : "16px",
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <motion.img
                    src="../assets/assets/images/logo/logo-dark.png"
                    alt="Logo"
                    className="h-6"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                  />
                  <motion.span className="text-white text-lg hidden sm:block">
                    Incubation Masters
                  </motion.span>
                </div>
                <motion.button
                  onClick={() => setIsOpen(false)}
                  className="text-white hover:text-gray-200 transition-colors"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X className="w-5 h-5" />
                </motion.button>
              </div>
            </motion.div>

            {/* Content Area */}
            <motion.div
              className="flex-1 overflow-hidden"
              style={getChatContentStyles()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {selectedFAQ ? (
                renderFAQDetail()
              ) : (
                <>
                  <AnimatePresence mode="wait">
                    {activeSection === "home" && renderHome()}
                    {activeSection === "messages" && renderMessages()}
                    {activeSection === "help" && (
                      <HelpSection
                        selectedHelp={selectedHelp}
                        setSelectedHelp={setSelectedHelp}
                      />
                    )}
                  </AnimatePresence>
                </>
              )}
            </motion.div>

            {/* Bottom Navigation */}
            <motion.div
              className="border-t bg-white p-3"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              style={{
                borderBottomLeftRadius: window.innerWidth <= 640 ? "0" : "16px",
                borderBottomRightRadius:
                  window.innerWidth <= 640 ? "0" : "16px",
              }}
            >
              <div className="flex justify-around">
                {[
                  { section: "home", icon: Home, label: "Home" },
                  {
                    section: "messages",
                    icon: MessageSquare,
                    label: "Messages",
                  },
                  { section: "help", icon: HelpCircle, label: "Help" },
                ].map(({ section, icon: Icon, label }) => (
                  <motion.button
                    key={section}
                    onClick={() => {
                      setActiveSection(section);
                      setSelectedFAQ(null);
                    }}
                    className={`flex flex-col items-center ${
                      activeSection === section
                        ? "text-[#09AFF4]"
                        : "text-gray-500"
                    }`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    <Icon className="w-5 h-5" />
                    <span className="text-xs mt-1">{label}</span>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Chatbot;
